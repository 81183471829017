import React from "react";
import LCEN from "../Common/assets/LCEN_GO2_Logo.png";

type Props = {
  headerText?: string;
  paragraphText?: string;
};

export const AuthLayout: React.FC<Props> = (props) => {
  return (
    <div className="position-relative auth-container">
      {/* <div className="left-top-image  d-none d-lg-block"></div>
      <div className="right-top-image d-none d-lg-block"></div>
      <div className="position-relative auth-text-holder justify-content-center">
        <div className="mw-400">
          <h1 className="text-white font-l fw-bold">{props.headerText}</h1>
          <p className="text-white">{props.paragraphText}</p>
        </div>
      </div>
      <div className="left-bottom-image  d-none d-lg-block"></div>
      <div className="right-bottom-image  d-none d-lg-block"></div> */}
      <div className="auth-block-container">
        <div
          className="auth-block btrr"
          // style={{
          //   backgroundImage: "url(/block-1.jpg)",
          // }}
        >
          <img
            src="/block-1.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="auth-block bblr"
          style={{ backgroundColor: "#FBBB9D" }}
        ></div>
        <div
          className="auth-block"
          // style={{
          //   backgroundImage: "url(/block-2.jpg)",
          // }}
        >
          <img
            src="/block-2.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
      <div
        className="auth-text-container"
        style={{ backgroundColor: "#DBF6FF" }}
      >
        <div
          style={{
            color: "#004F6B",
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: 500,
          }}
          className="mb-3"
        >
          Welcome to the Lung Cancer Engagement Network Survey
        </div>
        <div
          style={{
            color: "#004F6B",
            textAlign: "justify",
          }}
          className="font-m fw-medium"
        >
          As part of a Patient Centered Oriented Research Institute funded
          project, collaborators at GO2 for Lung Cancer, Memorial Sloan
          Kettering Cancer Center, and the University of Colorado Cancer Center
          aim to engage lung cancer patients/survivors and caregivers who
          reflect our lung cancer community to help identify priorities for lung
          cancer research. We hope you will consider taking this quick survey to
          launch this work.
        </div>
        <div className="lcen-logo-container">
          <img src={LCEN} alt="lcen-logo" style={{ maxHeight: "130px" }} />
        </div>
      </div>
      <div className="auth-block-container">
        <div
          className="auth-block bblr bbrr"
          style={{ backgroundColor: "#B2BEDC" }}
        ></div>
        <div className="auth-block lcen-logo">
          <img
            src={LCEN}
            alt="lcen-logo"
            style={{ maxHeight: "80%", maxWidth: "100%" }}
          />
        </div>
        <div className="auth-block btrr" style={{ backgroundColor: "#D7BCDC" }}>
          {/* <img
            src="/block-5.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          /> */}
        </div>
      </div>
      <div className="auth-block-container">
        <div
          className="auth-block btrr"
          // style={{
          //   backgroundImage: "url(/block-4.webp)",
          // }}
        >
          <img
            src="/block-3.png"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
        <div
          className="auth-block circle"
          style={{ backgroundColor: "#E6E6E6" }}
        ></div>
        <div
          className="auth-block btlr bbrr"
          // style={{
          //   backgroundColor: "#D3B4AC",
          // }}
        >
          <img
            src="/block-5.jpg"
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
