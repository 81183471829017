import { Button } from "antd";
import LogoContainer from "../Common/Logo-container";
// import LCEN from "../Common/assets/LCEN";

const InfoPage = ({ closeHandler }: any) => {
  return (
    <div
      className="thank-you-container info"
      style={{ backgroundColor: "#F8F9FB" }}
    >
      {/* <div>
        <img src={logo} alt="go2-logo" className="mb-l" width="200px" />
      </div> */}
      <div className="title-header mt-2">Welcome!</div>
      <div className="title-desc mb-5">
        Thank you for considering this project. The Lung Cancer Engagement
        Network (LCEN) is seeking input on research priorities from individuals
        affected by lung cancer (patients/survivors and caregivers) to improve
        care and outcomes.
      </div>
      <div className="title-desc mb-5">
        To make sure that we include a broad range of individuals affected by
        lung cancer, we first invite you to complete this brief (takes less than
        10 minutes) survey asking some questions about you and your lung cancer
        experience. We ask these questions to help us engage lung cancer
        patients/survivors and caregivers who reflect our lung cancer community.
      </div>
      <div className="title-desc mb-5">
        Once we have established our LCEN, we will then invite a subgroup of
        individuals to participate in a virtual discussion and priority-setting
        activity.
      </div>
      <div className="title-desc mb-5">
        Please take a few minutes to complete the LCEN survey, and please let us
        know if you have any questions at PCOR@go2.org. We need your voice to
        improve care and outcomes for individuals facing lung cancer!
      </div>
      {/* <div style={{ maxHeight: "200px" }}>
        <LCEN />
      </div> */}
      <Button
        className="tu-button"
        onClick={closeHandler}
        type="primary"
        style={{ flexShrink: 0 }}
      >
        Take me to the survey
      </Button>
      <LogoContainer />
      <div className="footer-text">
        This project, EACB-29067, is supported by the Patient-Centered Outcomes
        Research Institute (PCORI) and facilitated by GO2 for Lung Cancer, the
        Memorial Sloan Kettering Cancer Center, and the University of Colorado
        Cancer Center.
      </div>
    </div>
  );
};

export default InfoPage;
