import { useNavigate, useParams } from "react-router-dom";
import { Button, Layout } from "antd";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useAppDispatch } from "../../../redux/hooks";
import { setAppLoader } from "../../../redux/reducers/loaderSlice";
import {
  answerViewFormat,
  logicJumpHelper,
  questionResponseFormat,
  submitCRF,
} from "../../../helpers/question";
import { surveyHttp } from "../../../http";
import { setModalDetails } from "../../../redux/reducers/modalSlice";
import { errorToastMessage, toastMessage } from "../../../helpers/toastMessage";
import QuestionBlock from "../questionTypes/QuestionBlock";
import { getStudyId } from "../../../helpers/study";
import { CloseOutlined, MenuOutlined } from "@ant-design/icons";
import FixedSider from "./FixedSider";
import ResponsiveSider from "./ResponsiveSider";
import Progress from "./Progress";
import axios from "axios";

const { Header } = Layout;

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const Survey = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setLoading] = useState(true);
  const [phases, setPhases] = useState<any[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<string>("");
  const [selected, setSelected] = useState<any>(null);
  const { id } = useParams();
  const [questions, setQuestions] = useState<any[]>([]);
  const [logicJumps, setLogicJumps] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any>({});

  const [phaseToggle, setPhaseToggle] = useState(false);
  const phaseRef = useRef(phaseToggle);
  const [logicToggle, setLogicToggle] = useState(false);
  const toggleRef = useRef(false);
  const navigate = useNavigate();

  const [customIds, setCustomIds] = useState({ city: "", state: "" });

  const [hideQuestions, setHideQuestions] = useState<any[]>([]);
  const [locked, setLocked] = useState(false);
  const [hideSider, setHideSider] = useState(false);
  const [siderOpen, setSiderOpen] = useState(false);
  const [width] = useWindowSize();
  const [completionProgress, setCompletionProgress] = useState({
    percent: 0,
    total: 0,
    completed: 0,
  });

  useLayoutEffect(() => {
    if (width > 800) {
      setHideSider(false);
    } else {
      setHideSider(true);
    }
  }, [width]);

  useEffect(() => {
    const fetchPhases = async () => {
      try {
        setLoading(true);
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        let res = await surveyHttp.get(`/study/${studyId}/phase/survey-forms`, {
          headers: {
            Authorization: "Bearer " + id,
          },
        });
        let reload = false;
        if (phaseRef.current !== phaseToggle) {
          reload = true;
        }
        phaseRef.current = phaseToggle;
        let total = 0;
        let completed = 0;
        const editable = res.data.data.enableSurveyEdit;
        if (!editable) {
          dispatch(setAppLoader(false));
          sessionStorage.removeItem("hide-survey-info");
          navigate("/thank-you?complete=true");
          return;
        }
        const newPhases: any[] = res.data.data.forms.map((phase: any) => {
          const steps: any[] = phase.steps
            .map((ques: any) => {
              const attempts =
                ques?.patientAttempts?.filter(
                  (attempt: any) => attempt.phaseId === phase.id
                ) || [];
              return {
                id: ques?.questionnaire?.id,
                name: ques?.questionnaire?.name,
                isChinese: ques?.questionnaire?.isChinese,
                phaseId: phase.id,
                order: ques.order,
                editable: true,
                completed: attempts?.some((attempt: any) => {
                  return (
                    attempt.phaseId === phase.id &&
                    attempt.status === "completed"
                  );
                }),
                inprogress: attempts?.some((attempt: any) => {
                  return attempt.phaseId === phase.id;
                }),
              };
            })
            .sort((a: any, b: any) => a.order - b.order);
          total += steps.length;
          completed += steps.filter((s) => s.completed).length;
          return {
            id: phase.id,
            name: phase.name,
            steps: steps,
            completed: steps.every((step) => step.completed),
            inprogress: steps.some((step) => step.completed),
          };
        });
        let percent = 0;
        if (total) {
          percent = (completed / total) * 100;
          percent = +percent.toFixed(2);
        }
        setCompletionProgress({
          percent: percent,
          total: total,
          completed: completed,
        });
        setLocked(res.data.data.isLocked);
        setPhases(newPhases);
        setLoading(true);
        if (newPhases.length > 0) {
          if (reload) {
            let incompletePhase = newPhases.find((p) => !p.completed);
            if (!incompletePhase) {
              dispatch(setAppLoader(false));
              sessionStorage.removeItem("hide-survey-info");
              navigate("/thank-you?complete=true");
              return;
            }
            setSelectedPhase((prev) => (prev ? prev : newPhases[0]?.id));
            setSelected((prev: any) => {
              let queryQuestionnaire: any = null;
              const queryPhase = newPhases.find(
                (phase: any) => phase.id === prev?.phaseId
              );
              if (queryPhase) {
                queryQuestionnaire = queryPhase.steps.find(
                  (ques: any) => ques.id === prev?.id
                );
              }
              if (queryQuestionnaire && queryQuestionnaire?.completed) {
                const samePhase = newPhases.find(
                  (p) => p.id === queryQuestionnaire?.phaseId
                );
                queryQuestionnaire = samePhase.steps?.find(
                  (q: any) =>
                    !q.completed && queryQuestionnaire?.order < q.order
                );
                if (!queryQuestionnaire) {
                  const phase = newPhases.find((p) => !p.completed);
                  if (phase) {
                    queryQuestionnaire = phase.steps?.find(
                      (q: any) => !q.completed
                    );
                  }
                }
              }
              if (queryPhase && !queryQuestionnaire) {
                queryQuestionnaire = queryPhase.steps.find(
                  (ques: any) => ques.id === prev?.id
                );
              }
              return queryQuestionnaire || newPhases[0]?.steps?.[0] || null;
            });
          } else {
            let incompletePhase = newPhases.find((p) => !p.completed);
            if (!incompletePhase) {
              incompletePhase = newPhases[0];
            }
            let incompleteQuestionnaire = incompletePhase.steps?.find(
              (q: any) => !q.completed
            );
            if (!incompleteQuestionnaire) {
              incompleteQuestionnaire =
                incompletePhase.steps[incompletePhase.steps.length - 1];
            }
            setSelectedPhase(incompletePhase?.id);
            setSelected({
              ...incompleteQuestionnaire,
            });
          }
        } else {
          setSelected(null);
          dispatch(setAppLoader(false));
        }
      } catch (err) {
        setLoading(false);
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchPhases();
  }, [
    id,
    phaseToggle,
    dispatch,
    setPhases,
    setSelectedPhase,
    setLocked,
    setCompletionProgress,
    setLoading,
  ]);

  useEffect(() => {
    const fetchQues = async () => {
      try {
        setQuestions([]);
        setCustomIds({ city: "", state: "" });
        setLogicJumps([]);
        setHideQuestions([]);
        // setFormDirty(false);
        // setQueries({});
        dispatch(setAppLoader(true));
        let { data } = await surveyHttp.get(
          `/questionnaires/${selected.id}/survey-questions`,
          {
            headers: {
              Authorization: "Bearer " + id,
            },
          }
        );
        let questions = await questionResponseFormat(
          data.data.questions,
          data.data.logicJumps
        );
        setQuestions(questions);
        let city = "",
          state = "";
        questions.forEach((q) => {
          if (q.varName === "homec") {
            city = q.id;
          } else if (q.varName === "homest") {
            state = q.id;
          }
        });
        setCustomIds({ city, state });
        setLogicJumps(data.data.logicJumps);
        if (selected.completed || selected.inprogress) {
          const res = await surveyHttp.get(
            `/questionnaires/survey-responses?questionnaireId=${selected.id}&phaseId=${selected.phaseId}`,
            {
              headers: {
                Authorization: "Bearer " + id,
              },
            }
          );
          const newAnswer = answerViewFormat(res.data.data);
          setAnswers(newAnswer);
          setLogicToggle((prev) => !prev);
        } else {
          setAnswers({});
        }
        // const queryResponse = await http.get(
        //   `queries?paginate=false&phaseId=${selected.phaseId}&questionnaireId=${selected.id}&userId=${id}`
        // );
        // const queries = queryGroup(queryResponse.data.data?.rows);
        // setQueries(queries);
        dispatch(setAppLoader(false));
      } catch (err) {
        setAnswers({});
        setQuestions([]);
        setCustomIds({ city: "", state: "" });
        setLogicJumps([]);
        // setQueries({});
        // setFormDirty(false);
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    if (selected?.id) {
      fetchQues();
    } else {
      // setFormDirty(false);
      setAnswers({});
      setQuestions([]);
      setCustomIds({ city: "", state: "" });
      setLogicJumps([]);
      setHideQuestions([]);
      // setQueries({});
    }
  }, [
    id,
    selected,
    dispatch,
    setQuestions,
    setLogicJumps,
    setAnswers,
    setHideQuestions,
    setLogicToggle,
  ]);

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  const submitRequest = async (
    value: any,
    reason?: string,
    draft?: boolean
  ) => {
    try {
      const body: any = {
        programId: getStudyId(),
        questions: value,
        phaseId: selected.phaseId,
      };
      if (reason) {
        body.remark = reason;
      }
      if (draft) {
        body.draft = true;
        body.remark = body.remark || "draft";
      }
      const res = await surveyHttp.post(
        `/questionnaires/${selected.id}/survey_submit_response_web`,
        body,
        {
          headers: {
            Authorization: "Bearer " + id,
          },
        }
      );
      toastMessage("success", res.data.message);
      setPhaseToggle((prev) => !prev);
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const submitHandler = async (draft?: boolean) => {
    try {
      dispatch(setAppLoader(true));
      const { value, error, newAnswers } = submitCRF(
        answers,
        questions,
        hideQuestions
      );
      if (error && !draft) {
        setAnswers(newAnswers);
        toastMessage("warning", "Please enter values in all necessary fields");
        dispatch(setAppLoader(false));
      } else {
        if (value.length < 1) {
          toastMessage(
            "warning",
            "Atleast 1 question needs to be answered to submit the form"
          );
          dispatch(setAppLoader(false));
          return;
        }
        submitRequest(value, undefined, draft);
      }
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const prefillAddress = async (answer: any) => {
    try {
      if (
        customIds.city &&
        customIds.state &&
        answer?.textValue?.length === 5
      ) {
        dispatch(setAppLoader(true));
        const code = answer.textValue;
        let res = await axios.get(
          `https://api.zipcodestack.com/v1/search?codes=${code}&apikey=${process.env.REACT_APP_ZIP_KEY}&country=us`
        );
        const apiResponse = res.data?.results?.[code]?.[0];
        if (apiResponse) {
          setAnswers((ans: any) => {
            const newAns = {
              ...ans,
              [customIds.city]: {
                textValue: apiResponse.city,
              },
              [customIds.state]: {
                textValue: apiResponse.state,
              },
            };
            return newAns;
          });
        }
        dispatch(setAppLoader(false));
      }
    } catch (err) {
      // errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const saveAnswer = (
    key: string,
    answerObj: any,
    computeLogic?: boolean,
    prefill?: boolean
  ) => {
    if (selected?.editable && !locked) {
      setAnswers((ans: any) => {
        const newAns = {
          ...ans,
          [key]: answerObj,
        };
        return newAns;
      });
      // if (selected?.completed) {
      //   setFormDirty(true);
      // }
      if (computeLogic) {
        setLogicToggle((prev) => !prev);
      }
      if (prefill) {
        prefillAddress(answerObj);
      }
    }
  };

  useEffect(() => {
    if (logicToggle !== toggleRef.current) {
      const { hideQuestions, answers: newAnswers } = logicJumpHelper(
        answers,
        questions,
        logicJumps
      );
      setHideQuestions(hideQuestions);
      setAnswers(newAnswers);
      toggleRef.current = logicToggle;
    }
  }, [
    answers,
    logicToggle,
    questions,
    logicJumps,
    setHideQuestions,
    setAnswers,
  ]);

  const toggleSiderVisibility = () => {
    setSiderOpen((prev) => !prev);
  };

  return (
    <Layout>
      <Header className="survey-header">
        {hideSider ? (
          siderOpen ? (
            <Button
              icon={<CloseOutlined />}
              className="me-3"
              onClick={toggleSiderVisibility}
            />
          ) : (
            <Button
              icon={<MenuOutlined />}
              className="me-3"
              onClick={toggleSiderVisibility}
            />
          )
        ) : null}
        {/* <img src={logo} alt="go2-logo" width="200px" /> */}
        <div
          className="fw-bold"
          style={{ fontSize: "26px", lineHeight: "40px" }}
        >
          LCEN Survey
        </div>
      </Header>
      <Layout className="survey-layout">
        {hideSider ? (
          siderOpen ? (
            <ResponsiveSider
              selected={selected}
              selectedPhase={selectedPhase}
              setSelected={setSelected}
              setSelectedPhase={setSelectedPhase}
              phases={phases}
              closeSider={setSiderOpen}
              completionProgress={completionProgress}
            />
          ) : null
        ) : (
          <div className="survey-sider-wrapper fixed">
            <Progress completionProgress={completionProgress} />
            <FixedSider
              selected={selected}
              selectedPhase={selectedPhase}
              setSelected={setSelected}
              setSelectedPhase={setSelectedPhase}
              phases={phases}
            />
          </div>
        )}

        <div className="crf-builder">
          <div className="crf-container">
            {phases.length > 0 ? (
              <>
                {questions.length > 0 ? (
                  <div className="crf-question-container">
                    <div className="fw-semibold font-ml color-primary crf-header">
                      {selected?.name}
                    </div>
                    {questions.map((q, index) => {
                      if (q.type === "statement") {
                        return (
                          <div className="crf-statement d-flex" key={q.id}>
                            <div className="text-prime font-sm fw-medium">
                              {q.title}
                            </div>
                            {q.attachment && (
                              <span
                                className="ms-3 color-primary cp"
                                onClick={() => viewImage(q.attachment)}
                              >
                                View Image
                              </span>
                            )}
                          </div>
                        );
                      } else if (hideQuestions.includes(q.id)) {
                        return null;
                      } else {
                        return (
                          <QuestionBlock
                            index={index}
                            question={q}
                            key={q.id}
                            answers={answers}
                            hideQuestions={hideQuestions}
                            saveAnswer={saveAnswer}
                            showChinese={false}
                            selectedQuestionniare={selected}
                            userId={id}
                            // fetchQueries={fetchQueries}
                            // queries={queries}
                          />
                        );
                      }
                    })}
                    {selected?.editable && !locked && (
                      <div className="mt-2 mb-2 fw-semibold">
                        * - Item is required / mandatory
                      </div>
                    )}
                    <div className="d-flex align-items-center mt-5">
                      {selected?.editable && !locked && (
                        <>
                          <Button
                            type="primary"
                            style={{ height: "40px", borderRadius: "12px" }}
                            className="me-4"
                            onClick={() => submitHandler()}
                          >
                            <span className="fw-semibold font-sm">Submit</span>
                          </Button>
                          {!selected?.completed && (
                            <Button
                              type="primary"
                              style={{ height: "40px", borderRadius: "12px" }}
                              className="me-4"
                              onClick={() => submitHandler(true)}
                            >
                              <span className="fw-semibold font-sm">
                                Save as Draft
                              </span>
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                ) : (
                  // : selectedPhase === "medication" ? (
                  //   <CRFMedication locked={locked} />
                  // ) : selectedPhase === "incident" ? (
                  //   <IncidentReporting locked={locked} />
                  // ) : selectedPhase === "medical-history" ? (
                  //   <MedicalHistory locked={locked} />
                  // )
                  <div className="no-crf-message font-ml fw-semibold text-prime">
                    Loading your survey
                  </div>
                )}
              </>
            ) : (
              <div className="no-crf-message font-ml fw-semibold text-prime">
                {!isLoading ? "No surveys found" : "Loading your survey"}
              </div>
            )}
          </div>
        </div>
      </Layout>
    </Layout>
  );
};

export default Survey;
