import React from "react";
import { ThankYouLogo } from "./asset";
// import logo from "../../assets/img/go_logo.png";
import { useQuery } from "../../helpers/customHooks";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import LogoContainer from "../Common/Logo-container";

const ThankYou: React.FC = () => {
  const complete = useQuery().get("complete");

  const navigate = useNavigate();

  const closeHandler = () => {
    navigate("/pcori");
  };

  return (
    <div className="thank-you-container thank-you">
      {/* <div className="logo-wrapper"> */}
      <LogoContainer />
      {/* </div> */}
      <div style={{ marginBottom: "20px" }}>
        <ThankYouLogo />
      </div>
      {!complete && <div className="title-header">Thanks</div>}
      <div className="title-desc">
        {complete
          ? "Thank you for completing this survey; we appreciate your time."
          : "Please check your email for a link to take the survey."}
      </div>
      {complete && (
        <Button
          type="primary"
          className="tu-button mt-5"
          onClick={closeHandler}
        >
          Close
        </Button>
      )}
    </div>
  );
};

export default ThankYou;
