import {
  useLocation,
  useNavigate,
  useParams,
  // useSearchParams,
} from "react-router-dom";
import {
  Button,
  Dropdown,
  Menu,
  //  Modal
} from "antd";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { setAppLoader } from "../../redux/reducers/loaderSlice";
import {
  answerViewFormat,
  logicJumpHelper,
  // queryGroup,
  questionResponseFormat,
  submitCRF,
} from "../../helpers/question";
import http from "../../http";
import { setModalDetails } from "../../redux/reducers/modalSlice";
import { ChevronLeftIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { errorToastMessage, toastMessage } from "../../helpers/toastMessage";
import QuestionBlock from "./questionTypes/QuestionBlock";
import { getStudyId } from "../../helpers/study";
import { canEditCRFs } from "../../helpers/roles";
import axios from "axios";
// import CRFMedication from "./Medication";
// import IncidentReporting from "./IncidentReporting";
// import { canEditCRFs, canReconcileCRFs } from "../../helpers/roles";
// import MedicalHistory from "./MedicalHistory";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const confirm = Modal.confirm;

const CRF = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const role = useAppSelector((state) => state.user.role);
  const patientId = useQuery().get("patientId");
  const [showPro] = useState(false);
  const [phases, setPhases] = useState<any[]>([]);
  const [selectedPhase, setSelectedPhase] = useState<string>("");
  const [selected, setSelected] = useState<any>(null);
  const { id } = useParams();
  const [questions, setQuestions] = useState<any[]>([]);
  const [logicJumps, setLogicJumps] = useState<any[]>([]);
  const [answers, setAnswers] = useState<any>({});
  const [formDirty, setFormDirty] = useState<any>({});

  const [customIds, setCustomIds] = useState({ city: "", state: "" });

  const [phaseToggle, setPhaseToggle] = useState(false);
  const [logicToggle, setLogicToggle] = useState(false);
  const toggleRef = useRef(false);
  const proRef = useRef(showPro);
  // const showRelationshipPopup = useRef(false);

  const [hideQuestions, setHideQuestions] = useState<any[]>([]);
  // const [queries, setQueries] = useState<any>({});

  // const [showChinese, setChinese] = useState(false);
  const [locked, setLocked] = useState(false);
  // let [, setSearchParams] = useSearchParams();

  // const toggleChinese = () => {
  //   setChinese((prev) => !prev);
  // };

  // const togglePro = () => {
  //   setPro((prev) => !prev);
  // };

  useEffect(() => {
    const fetchPhases = async () => {
      try {
        dispatch(setAppLoader(true));
        const studyId = getStudyId();
        // &screening=true&isEpro=${showPro}
        let res = await http.get(`/study/${studyId}/phase/forms?userId=${id}`);
        let reload = false;
        if (proRef.current !== showPro) {
          reload = true;
          proRef.current = showPro;
        }
        const newPhases = res.data.data.forms.map((phase: any) => {
          const steps: any[] = phase.steps
            .map((ques: any) => {
              const attempts =
                ques?.patientAttempts?.filter(
                  (attempt: any) => attempt.phaseId === phase.id
                ) || [];
              return {
                id: ques?.questionnaire?.id,
                name: ques?.questionnaire?.name,
                isChinese: ques?.questionnaire?.isChinese,
                phaseId: phase.id,
                order: ques.order,
                editable: false, //canEditCRFs(role),
                // editable:
                //   (attempts?.some((attempt: any) => {
                //     return (
                //       attempt.phaseId === phase.id && !attempt.dataVerifierId
                //     );
                //   }) ||
                //     attempts?.length === 0) &&
                //   canEditCRFs(role),
                completed: attempts?.some((attempt: any) => {
                  return (
                    attempt.phaseId === phase.id &&
                    attempt.status === "completed"
                  );
                }),
                inprogress: attempts?.some((attempt: any) => {
                  if (showPro && attempt.isParticipantEditable) {
                    return (
                      attempt.phaseId === phase.id && attempt.startedAt !== null
                    );
                  } else {
                    return attempt.phaseId === phase.id;
                  }
                }),
                // dataVerified: attempts?.some((attempt: any) => {
                //   return (
                //     attempt.phaseId === phase.id &&
                //     attempt.status === "completed" &&
                //     attempt.dataVerifierId
                //   );
                // }),
                // dataVerify:
                //   canReconcileCRFs(role) &&
                //   attempts?.some((attempt: any) => {
                //     return (
                //       attempt.phaseId === phase.id &&
                //       attempt.status === "completed" &&
                //       !attempt.dataVerifierId
                //     );
                //   }),
              };
            })
            .sort((a: any, b: any) => a.order - b.order);
          return {
            id: phase.id,
            name: phase.name,
            steps: steps,
            completed: steps.every((step) => step.completed),
            inprogress: steps.some((step) => step.completed),
          };
        });
        setLocked(res.data.data.isLocked);
        // showRelationshipPopup.current =
        //   res.data.data.stage === 0 && !res.data.data.archived;
        // if (crfType !== "screening" && !showPro) {
        //   newPhases.push({
        //     id: "medication",
        //     name: "Concomitant Medication",
        //     steps: [],
        //     completed: false,
        //     inprogress: true,
        //   });
        //   newPhases.push({
        //     id: "incident",
        //     name: "Adverse Events",
        //     steps: [],
        //     completed: false,
        //     inprogress: true,
        //   });
        // }
        // if (crfType === "screening") {
        //   newPhases.push({
        //     id: "medical-history",
        //     name: "Medical History",
        //     steps: [],
        //     completed: false,
        //     inprogress: true,
        //   });
        // }
        setPhases(newPhases);
        if (newPhases.length > 0) {
          const sPhase = sessionStorage.getItem("crf-phase");
          const qid = sessionStorage.getItem("crf-question");
          sessionStorage.removeItem("crf-phase");
          sessionStorage.removeItem("crf-question");
          if (sPhase && qid) {
            const queryPhase = newPhases.find(
              (phase: any) => phase.id === sPhase
            );
            if (queryPhase) {
              const queryQuestionnaire = queryPhase.steps.find(
                (ques: any) => ques.id === qid
              );
              if (queryQuestionnaire) {
                setSelected(queryQuestionnaire);
                setSelectedPhase(sPhase);
                return;
              }
            }
          }
          setSelectedPhase((prev) =>
            !reload && prev ? prev : newPhases[0]?.id
          );
          setSelected((prev: any) => {
            let queryQuestionnaire = null;
            const queryPhase = newPhases.find(
              (phase: any) => phase.id === prev?.phaseId
            );
            if (queryPhase) {
              queryQuestionnaire = queryPhase.steps.find(
                (ques: any) => ques.id === prev?.id
              );
            }
            return queryQuestionnaire || newPhases[0]?.steps?.[0] || null;
          });
        } else {
          setSelected(null);
          dispatch(setAppLoader(false));
        }
      } catch (err) {
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    fetchPhases();
  }, [
    dispatch,
    setPhases,
    id,
    setSelectedPhase,
    phaseToggle,
    setLocked,
    showPro,
    role,
  ]);

  useEffect(() => {
    const fetchQues = async () => {
      try {
        setQuestions([]);
        setCustomIds({ city: "", state: "" });
        setLogicJumps([]);
        setHideQuestions([]);
        setFormDirty(false);
        // setQueries({});
        dispatch(setAppLoader(true));
        let { data } = await http.get(
          `/questionnaires/${selected.id}/questions`
        );
        let questions = await questionResponseFormat(
          data.data.questions,
          data.data.logicJumps
        );
        setQuestions(questions);
        let city = "",
          state = "";
        questions.forEach((q) => {
          if (q.varName === "homec") {
            city = q.id;
          } else if (q.varName === "homest") {
            state = q.id;
          }
        });
        setCustomIds({ city, state });
        setLogicJumps(data.data.logicJumps);
        if (selected.completed || selected.inprogress) {
          const res = await http.get(
            `/questionnaires/responses?patientId=${id}&questionnaireId=${selected.id}&phaseId=${selected.phaseId}`
          );
          const newAnswer = answerViewFormat(res.data.data);
          setAnswers(newAnswer);
          setLogicToggle((prev) => !prev);
        } else {
          setAnswers({});
        }
        // const queryResponse = await http.get(
        //   `queries?paginate=false&phaseId=${selected.phaseId}&questionnaireId=${selected.id}&userId=${id}`
        // );
        // const queries = queryGroup(queryResponse.data.data?.rows);
        // setQueries(queries);
        dispatch(setAppLoader(false));
      } catch (err) {
        setAnswers({});
        setQuestions([]);
        setCustomIds({ city: "", state: "" });
        setLogicJumps([]);
        // setQueries({});
        setFormDirty(false);
        errorToastMessage(err as Error);
        dispatch(setAppLoader(false));
      }
    };
    if (selected?.id) {
      fetchQues();
    } else {
      setFormDirty(false);
      setAnswers({});
      setQuestions([]);
      setCustomIds({ city: "", state: "" });
      setLogicJumps([]);
      setHideQuestions([]);
      // setQueries({});
    }
  }, [
    id,
    selected,
    dispatch,
    setQuestions,
    setLogicJumps,
    setAnswers,
    setHideQuestions,
    setLogicToggle,
  ]);

  // const fetchQueries = async () => {
  //   try {
  //     dispatch(setAppLoader(true));
  //     const queryResponse = await http.get(
  //       `queries?paginate=false&phaseId=${selected.phaseId}&questionnaireId=${selected.id}&userId=${id}`
  //     );
  //     const queries = queryGroup(queryResponse.data.data?.rows);
  //     setQueries(queries);
  //     dispatch(setAppLoader(false));
  //   } catch (err) {
  //     dispatch(setAppLoader(false));
  //     errorToastMessage(err as Error);
  //   }
  // };

  const viewImage = (attachment: any) => {
    dispatch(
      setModalDetails({
        type: "VIEW_IMAGE",
        modalProps: {
          show: true,
          attachment: attachment,
        },
      })
    );
  };

  const handleBack = () => {
    navigate(-1);
  };

  const submitRequest = async (
    value: any,
    reason?: string,
    draft?: boolean
    // relationship?: any
  ) => {
    try {
      const body: any = {
        programId: getStudyId(),
        userId: id,
        questions: value,
        phaseId: selected.phaseId,
      };
      if (reason) {
        body.remark = reason;
      }
      if (draft) {
        body.draft = true;
        body.remark = body.remark || "draft";
      }
      // if (relationship) {
      //   body.relationshipType = relationship.relationshipType;
      //   body.relationshipId = relationship.relationshipId;
      // }
      const res = await http.post(
        `/questionnaires/${selected.id}/submit_response_web`,
        body
      );
      toastMessage("success", res.data.message);
      setPhaseToggle((prev) => !prev);
    } catch (err) {
      dispatch(setAppLoader(false));
      errorToastMessage(err as Error);
    }
  };

  const revertChanges = () => {
    setSelected((prev: any) => {
      return { ...prev };
    });
  };

  const submitHandler = async (draft?: boolean) => {
    try {
      dispatch(setAppLoader(true));
      const { value, error, newAnswers } = submitCRF(
        answers,
        questions,
        hideQuestions
      );
      if (error && !draft) {
        setAnswers(newAnswers);
        toastMessage("warning", "Please enter values in all necessary fields");
        dispatch(setAppLoader(false));
      } else {
        if (value.length < 1) {
          toastMessage(
            "warning",
            "Atleast 1 question needs to be answered to submit the form"
          );
          dispatch(setAppLoader(false));
          return;
        }
        if (selected?.completed || selected?.inprogress) {
          dispatch(setAppLoader(false));
          dispatch(
            setModalDetails({
              type: "GCP_FORM_SUBMISSION",
              modalProps: {
                show: true,
                isDraft: draft,
                value: value,
                successCallback: submitRequest,
                failureCallback: revertChanges,
              },
            })
          );
        } else {
          // if (
          //   selected.id === process.env.REACT_APP_EOV_ID &&
          //   !draft &&
          //   showRelationshipPopup.current
          // ) {
          //   dispatch(setAppLoader(false));
          //   dispatch(
          //     setModalDetails({
          //       type: "RANDOMIZATION_ENTRY_MODAL",
          //       modalProps: {
          //         show: true,
          //         value: value,
          //         successCallback: submitRequest,
          //       },
          //     })
          //   );
          // } else {
          submitRequest(value, undefined, draft);
          // }
        }
      }
    } catch (err) {
      errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const prefillAddress = async (answer: any) => {
    try {
      if (
        customIds.city &&
        customIds.state &&
        answer?.textValue?.length === 5
      ) {
        dispatch(setAppLoader(true));
        const code = answer.textValue;
        let res = await axios.get(
          `https://api.zipcodestack.com/v1/search?codes=${code}&apikey=${process.env.REACT_APP_ZIP_KEY}&country=us`
        );
        const apiResponse = res.data?.results?.[code]?.[0];
        if (apiResponse) {
          setAnswers((ans: any) => {
            const newAns = {
              ...ans,
              [customIds.city]: {
                textValue: apiResponse.city,
              },
              [customIds.state]: {
                textValue: apiResponse.state,
              },
            };
            return newAns;
          });
        }
        dispatch(setAppLoader(false));
      }
    } catch (err) {
      // errorToastMessage(err as Error);
      dispatch(setAppLoader(false));
    }
  };

  const saveAnswer = (
    key: string,
    answerObj: any,
    computeLogic?: boolean,
    prefill?: boolean
  ) => {
    if (selected?.editable && !locked) {
      setAnswers((ans: any) => {
        const newAns = {
          ...ans,
          [key]: answerObj,
        };
        return newAns;
      });
      if (selected?.completed) {
        setFormDirty(true);
      }
      if (computeLogic) {
        setLogicToggle((prev) => !prev);
      }
      if (prefill) {
        prefillAddress(answerObj);
      }
    }
  };

  useEffect(() => {
    if (logicToggle !== toggleRef.current) {
      const { hideQuestions, answers: newAnswers } = logicJumpHelper(
        answers,
        questions,
        logicJumps
      );
      setHideQuestions(hideQuestions);
      setAnswers(newAnswers);
      toggleRef.current = logicToggle;
    }
  }, [
    answers,
    logicToggle,
    questions,
    logicJumps,
    setHideQuestions,
    setAnswers,
  ]);

  const getScores = async (qid: string, phaseId: string) => {
    dispatch(
      setModalDetails({
        type: "VIEW_CRF_LOG",
        modalProps: {
          show: true,
          qid: qid,
          phaseId: phaseId,
          id: id,
        },
      })
    );
  };

  // const handleReconcile = async () => {
  //   const callback = async () => {
  //     try {
  //       dispatch(setAppLoader(true));
  //       const res = await http.post(
  //         `/questionnaires/${selected.id}/verify-ecrf`,
  //         {
  //           programId: getStudyId(),
  //           userId: id,
  //           phaseId: selected.phaseId,
  //         }
  //       );
  //       toastMessage("success", res.data.message);
  //       setPhaseToggle((prev) => !prev);
  //       dispatch(setAppLoader(false));
  //     } catch (err) {
  //       dispatch(setAppLoader(false));
  //       errorToastMessage(err as Error);
  //     }
  //   };
  //   confirm({
  //     title: "Data verify",
  //     content:
  //       "Are you sure you'd like to verify this data? This action can't be undone.",
  //     onOk() {
  //       callback();
  //     },
  //     onCancel() {},
  //     okText: "Yes",
  //     cancelText: "No",
  //   });
  // };

  const questionMenu = (question: any) => (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => getScores(question.id, question.phaseId)}
      >
        View Submission log
      </Menu.Item>
    </Menu>
  );

  return (
    <div className="crf-builder">
      <div className="content-header">
        <ChevronLeftIcon
          style={{ height: "36px", width: "36px" }}
          className="me-5 text-secondary cp"
          onClick={handleBack}
        />
        <div className="font-m fw-semibold font-l me-5 text-secondary">
          {patientId || "Patient Id"}
        </div>
        <span style={{ marginLeft: "auto" }}></span>
        {formDirty && !locked && selected?.editable && (
          <span
            className="fw-semibold font-m me-3"
            style={{ color: "#f05252" }}
          >
            Please submit your changes
          </span>
        )}
        {/* <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={toggleChinese}
        >
          <span className="fw-semibold font-sm">
            {showChinese ? "Show English Text" : "Show Chinese Text"}
          </span>
        </Button> */}
        {/* {crfType === "study" && (
          <Button
            type="primary"
            style={{ height: "40px", borderRadius: "12px" }}
            className="me-4"
            onClick={togglePro}
          >
            <span className="fw-semibold font-sm">
              {showPro ? "Show Crf" : "Show E-pro"}
            </span>
          </Button>
        )} */}
      </div>
      <div className="crf-container">
        {phases.length > 0 ? (
          <>
            <div className="crf-sider">
              {phases.map((phase) => {
                const selection = () => {
                  setSelectedPhase((prev) => {
                    // if (
                    //   (phase.id === "medication" ||
                    //     phase.id === "incident" ||
                    //     phase.id === "medical-history") &&
                    //   prev === phase.id
                    // ) {
                    //   return phase.id;
                    // }
                    return prev === phase.id ? null : phase.id;
                  });
                  // if (
                  //   phase.id === "medication" ||
                  //   phase.id === "incident" ||
                  //   phase.id === "medical-history"
                  // ) {
                  //   setSelected(null);
                  // }
                };
                return (
                  <div
                    className="phase-container"
                    key={phase.id}
                    onClick={selection}
                  >
                    <div className="block">
                      <div className="color-primary font-m fw-semibold mb-2">
                        {phase.name}
                      </div>
                      <div
                        className={
                          phase.completed
                            ? "phase-progress completed"
                            : selectedPhase === phase.id || phase.inprogress
                            ? "phase-progress in-progress"
                            : "phase-progress not-started"
                        }
                      >
                        {phase.completed
                          ? "Completed"
                          : selectedPhase === phase.id || phase.inprogress
                          ? "In progress"
                          : "Not Started"}
                      </div>
                    </div>
                    {selectedPhase === phase.id &&
                      phase?.steps?.map((visit: any) => {
                        const isSelected =
                          visit.id === selected?.id &&
                          visit.phaseId === selected?.phaseId;
                        return (
                          <div
                            key={visit.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelected(visit);
                            }}
                            className={
                              isSelected
                                ? "selected-block block ques-block"
                                : "block ques-block"
                            }
                          >
                            <div>
                              <div className="form-name">{visit.name}</div>
                              <div
                                className={
                                  visit.completed
                                    ? "form-progress completed"
                                    : isSelected || visit.inprogress
                                    ? "form-progress inprogress"
                                    : "form-progress"
                                }
                              >
                                {visit.completed
                                  ? visit?.dataVerified
                                    ? "Data Verified"
                                    : "Completed"
                                  : isSelected || visit.inprogress
                                  ? "In progress"
                                  : "Not started"}
                              </div>
                            </div>
                            {(visit.completed || visit.inprogress) && (
                              <span onClick={(e) => e.stopPropagation()}>
                                <Dropdown
                                  overlay={questionMenu(visit)}
                                  trigger={["click"]}
                                >
                                  <DotsVerticalIcon
                                    className="hero-icon cp"
                                    style={{
                                      color: "#71717A",
                                      marginLeft: "auto",
                                    }}
                                  />
                                </Dropdown>
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                );
              })}
            </div>
            {questions.length > 0 ? (
              <div className="crf-question-container">
                <div className="fw-semibold font-ml color-primary crf-header">
                  {selected?.name}
                </div>
                {questions.map((q, index) => {
                  if (q.type === "statement") {
                    return (
                      <div className="crf-statement d-flex" key={q.id}>
                        <div className="text-prime font-sm fw-medium">
                          {q.title}
                        </div>
                        {q.attachment && (
                          <span
                            className="ms-3 color-primary cp"
                            onClick={() => viewImage(q.attachment)}
                          >
                            View Image
                          </span>
                        )}
                      </div>
                    );
                  } else if (hideQuestions.includes(q.id)) {
                    return null;
                  } else {
                    return (
                      <QuestionBlock
                        index={index}
                        question={q}
                        key={q.id}
                        answers={answers}
                        hideQuestions={hideQuestions}
                        saveAnswer={saveAnswer}
                        showChinese={false}
                        selectedQuestionniare={selected}
                        userId={id}
                        // fetchQueries={fetchQueries}
                        // queries={queries}
                      />
                    );
                  }
                })}
                <div className="d-flex align-items-center mt-5">
                  {selected?.editable && !locked && (
                    <>
                      <Button
                        type="primary"
                        style={{ height: "40px", borderRadius: "12px" }}
                        className="me-4"
                        onClick={() => submitHandler()}
                      >
                        <span className="fw-semibold font-sm">Submit</span>
                      </Button>
                      {!selected?.completed && (
                        <Button
                          type="primary"
                          style={{ height: "40px", borderRadius: "12px" }}
                          className="me-4"
                          onClick={() => submitHandler(true)}
                        >
                          <span className="fw-semibold font-sm">
                            Save as Draft
                          </span>
                        </Button>
                      )}
                      <Button
                        style={{ height: "40px", borderRadius: "12px" }}
                        className="me-4"
                        onClick={handleBack}
                      >
                        <span className="fw-semibold font-sm">Cancel</span>
                      </Button>
                    </>
                  )}
                  {/* {selected?.dataVerify && !locked && (
                    <Button
                      type="primary"
                      style={{ height: "40px", borderRadius: "12px" }}
                      className="me-4"
                      onClick={handleReconcile}
                    >
                      <span className="fw-semibold font-sm">Verify Data</span>
                    </Button>
                  )} */}
                </div>
              </div>
            ) : (
              // : selectedPhase === "medication" ? (
              //   <CRFMedication locked={locked} />
              // ) : selectedPhase === "incident" ? (
              //   <IncidentReporting locked={locked} />
              // ) : selectedPhase === "medical-history" ? (
              //   <MedicalHistory locked={locked} />
              // )
              <div className="no-crf-message font-ml fw-semibold text-prime">
                Please select a CRF to proceed
              </div>
            )}
          </>
        ) : (
          <div className="no-crf-message font-ml fw-semibold text-prime">
            No phases found
          </div>
        )}
      </div>
    </div>
  );
};

export default CRF;
