import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../redux/hooks";

const ParticipantUrlSetter = () => {
  let [, setSearchParams] = useSearchParams();
  const {
    searchTerm,
    currentPage,
    sort,
    // selectedTab,
    searchType,
    filter,
  } = useAppSelector((state) => state.participant);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("page", currentPage.toString());
    if (searchTerm) {
      params.set("search", searchTerm);
    }
    if (searchType) {
      params.set("searchType", searchType);
    }
    if (filter) {
      params.set("filter", filter);
    }
    if (sort) {
      params.set("orderBy", sort.orderBy);
      params.set("sortBy", sort.sortBy);
    }
    // if (selectedTab) {
    //   params.set("type", selectedTab);
    // }
    setSearchParams(params, {
      replace: true,
    });
  }, [
    setSearchParams,
    searchTerm,
    currentPage,
    sort,
    filter,
    // selectedTab,
    searchType,
  ]);

  return <></>;
};

export default ParticipantUrlSetter;
