import { Button, DatePicker, Image, Input, TimePicker, Upload } from "antd";
import moment from "moment";
import { useEffect, useRef } from "react";
import { UploadImage } from "../../../helpers/imageUpload";
import { toastMessage } from "../../../helpers/toastMessage";
import { useAppDispatch } from "../../../redux/hooks";
import { UploadImageIcon } from "../../Common/Icons";
import * as yup from "yup";

const { Dragger } = Upload;

export const InputType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id, properties, uploadable } = question;
  const dispatch = useAppDispatch();
  const draggerRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    try {
      if (draggerRef.current) {
        const element = draggerRef.current.getElementsByTagName("input")?.[0];
        if (element) {
          element.setAttribute("capture", "environment");
        }
      }
    } catch (err) {}
  }, []);

  const saveInput = (event: any) => {
    let val = event.target.value;
    if (answer) {
      saveAnswer(id, {
        ...answer,
        numberValue: val,
      });
    } else {
      saveAnswer(id, {
        numberValue: val,
      });
    }
  };

  const onBlur = (event: any) => {
    if (answer) {
      let val = event.target.value;
      let error;
      if (question.enforceDecimal) {
        val = parseFloat(val);
      } else {
        val = parseInt(val);
      }
      if (isNaN(val)) {
        val = "";
        if (question.required) {
          error = "This is a required field";
        }
      } else if (val > properties.maxRange) {
        val = properties.maxRange;
      } else if (val < properties.minRange) {
        val = properties.minRange;
      }
      saveAnswer(
        id,
        {
          ...answer,
          numberValue: val,
          error: error,
        },
        question.type === "number" ? true : false
      );
    }
  };

  const customRequest = (fileObj: any) => {
    const handleUpload = (data: any[]) => {
      if (answer) {
        saveAnswer(id, {
          ...answer,
          textValue: data[0].postUploadImageUrl,
        });
      } else {
        saveAnswer(id, {
          textValue: data[0].postUploadImageUrl,
        });
      }
    };
    dispatch(UploadImage(fileObj, handleUpload, "crf_image"));
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      toastMessage("warning", `Image must smaller than 5MB!`);
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <>
      <div className="crf-text-field d-flex align-items-center">
        <Input
          type="number"
          className="crf-number"
          placeholder="Enter value here"
          max={properties.maxRange}
          min={properties.minRange}
          value={answer?.numberValue}
          onChange={saveInput}
          onBlur={onBlur}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
        />
      </div>
      {uploadable && (
        <span ref={draggerRef}>
          <Dragger
            className="crf-file"
            multiple={false}
            accept="image/jpeg,image/png,image/jpg"
            showUploadList={false}
            customRequest={customRequest}
            beforeUpload={beforeUpload}
          >
            {answer?.textValue ? (
              <Image
                src={answer.textValue}
                alt="answer"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <UploadImageIcon />
                <span className="ms-3 fw-semibold font-sm color-primary">
                  {`Drop files to upload (max file size - 5MB)`}
                </span>
              </div>
            )}
          </Dragger>
        </span>
      )}
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </>
  );
};

export const TextType: React.FC<any> = ({ question, saveAnswer, answer }) => {
  const { properties, type, id } = question;

  const saveInput = (event: any) => {
    if (answer) {
      saveAnswer(id, {
        ...answer,
        textValue: event.target.value,
      });
    } else {
      saveAnswer(id, {
        textValue: event.target.value,
      });
    }
  };

  const onBlur = (event: any) => {
    if (answer) {
      let val = event.target.value || "";
      let error;
      if (!val.trim() && question.required) {
        error = "This is a required field";
      } else if (
        val.trim() &&
        ["phonem", "phoneh"].includes(question.varName)
      ) {
        const len = val.replace(/[^0-9]/g, "").length;
        if (len !== 10) {
          error = "Please enter a valid phone number";
        }
      } else if (
        val.trim() &&
        [
          "snow_e1",
          "snow_e2",
          "snow_e3",
          "email1a",
          "email1b",
          "email2a",
          "email2b",
        ].includes(question.varName)
      ) {
        let schema = yup.string().email();
        let result = schema.isValidSync(val);
        if (!result) {
          error = "Please enter a valid email";
        }
      }
      saveAnswer(
        id,
        {
          ...answer,
          textValue: val,
          error,
        },
        true,
        question.varName === "homez"
      );
    }
  };

  return (
    <div className="crf-text-field">
      <Input.TextArea
        placeholder="Type your text here"
        rows={type === "short_text" ? 2 : 3}
        showCount
        maxLength={properties.maxRange}
        value={answer?.textValue}
        onChange={saveInput}
        onBlur={onBlur}
      />
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </div>
  );
};

export const DateType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id } = question;
  const format =
    question.type === "date_time" ? "DD/MM/YYYY HH:mm" : "DD/MM/YYYY";

  const saveInput = (val: any) => {
    if (answer) {
      const dateValue = moment.utc(val, format).toISOString();
      const newAnswer = {
        ...answer,
        dateValue,
      };
      if (question.required && !dateValue) {
        newAnswer["error"] = "This is a required field";
      }
      if (dateValue && newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          dateValue: moment.utc(val, format).toISOString(),
        },
        true
      );
    }
  };

  return (
    <div className="crf-text-field">
      <DatePicker
        showTime={question.type === "date_time"}
        className="date-selector"
        format={format}
        value={answer?.dateValue ? moment.utc(answer?.dateValue) : null}
        onChange={(_1, dateString) => {
          saveInput(dateString);
        }}
        inputReadOnly
      />
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </div>
  );
};

export const TimeType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const { id } = question;

  const saveInput = (val: any) => {
    if (answer) {
      const textValue = val;
      const newAnswer = {
        ...answer,
        textValue,
      };
      if (question.required && !textValue) {
        newAnswer["error"] = "This is a required field";
      }
      if (textValue && newAnswer.error) {
        delete newAnswer["error"];
      }
      saveAnswer(id, newAnswer, true);
    } else {
      saveAnswer(
        id,
        {
          textValue: val,
        },
        true
      );
    }
  };

  return (
    <div className="crf-text-field">
      <TimePicker
        className="date-selector"
        format="HH:mm"
        value={answer?.textValue ? moment(answer?.textValue, "HH:mm") : null}
        onChange={(val, timestring) => {
          saveInput(timestring);
        }}
        allowClear
        inputReadOnly
      />
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </div>
  );
};

export const FileType: React.FC<any> = ({ question, answer, saveAnswer }) => {
  const dispatch = useAppDispatch();
  const { id } = question;
  const draggerRef = useRef<HTMLDivElement>(null);

  // useEffect(() => {
  //   try {
  //     if (draggerRef.current) {
  //       const element = draggerRef.current.getElementsByTagName("input")?.[0];
  //       if (element) {
  //         element.setAttribute("capture", "environment");
  //       }
  //     }
  //   } catch (err) {}
  // }, []);

  const customRequest = (fileObj: any) => {
    const handleUpload = (data: any[]) => {
      if (answer) {
        const newAnswer = {
          ...answer,
          textValue: data[0].postUploadImageUrl,
        };
        if (newAnswer.error) {
          delete newAnswer["error"];
        }
        saveAnswer(id, newAnswer);
      } else {
        saveAnswer(id, {
          textValue: data[0].postUploadImageUrl,
        });
      }
    };
    dispatch(UploadImage(fileObj, handleUpload, "crf_image"));
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/gif" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      toastMessage("warning", "You can only upload JPG/PNG/JPEG/GIF/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < question.properties.maxRange;
    if (!isLt2M) {
      toastMessage(
        "warning",
        `Image must smaller than ${question.properties.maxRange}MB!`
      );
    }
    return isJpgOrPng && isLt2M;
  };

  const downloadFile: React.MouseEventHandler<HTMLElement> = (event) => {
    event.stopPropagation();
    window.open(answer?.textValue, "_blank");
  };

  return (
    <div ref={draggerRef}>
      <Dragger
        className="crf-file"
        multiple={false}
        accept="image/jpeg,image/png,image/jpg,application/pdf,image/gif"
        showUploadList={false}
        customRequest={customRequest}
        beforeUpload={beforeUpload}
      >
        {answer?.textValue ? (
          <Button type="primary" htmlType="button" onClick={downloadFile}>
            Download File
          </Button>
        ) : (
          <div className="d-flex align-items-center justify-content-center">
            <UploadImageIcon />
            <span className="ms-3 fw-semibold font-sm color-primary">
              {`Drop files to upload (max file size - ${question.properties.maxRange}MB)`}
            </span>
          </div>
        )}
      </Dragger>
      {answer?.error && <div className="input-error">{answer?.error}</div>}
    </div>
  );
};
