import { Button, Input, Modal, Select, Switch, Tabs } from "antd";
import { useEffect, useState } from "react";
import { choice_types, number_types, QuestionLabelMap } from "./questionTypes";
import { v4 as uuid } from "uuid";
import ResponseLogic from "./ResponseLogic";
import BranchLogic from "./BranchLogic";
import GridLogic from "./GridLogic";

const { TabPane } = Tabs;

type Props = {
  questions: any;
  modalProp: any;
  setModal: Function;
  setInnerFields: Function;
  showChinese: boolean;
};

const othersField = ["multiple_choice", "checkbox"];
const noLogicFields = [
  "score_input",
  "grid",
  "upload_file",
  "statement",
  "group",
  "checkbox",
  "time",
];
const noResonseFields = [
  "grid",
  "upload_file",
  "score_input",
  "statement",
  "group",
  "time",
];

const QuestionPropertiesModal: React.FC<Props> = ({
  questions,
  modalProp,
  setModal,
  setInnerFields,
  showChinese,
}) => {
  const [question, setQuestion] = useState<any>(null);

  const closeModal = () => {
    setModal(null);
  };

  useEffect(() => {
    let question = questions[modalProp.index];
    if (modalProp.subIndex !== undefined) {
      question = question["questions"][modalProp.subIndex];
    }
    const cloned = JSON.parse(JSON.stringify(question));
    setQuestion(cloned);
  }, [modalProp, questions, setQuestion]);

  const modifyQuestion = (question: any) => {
    setQuestion(question);
  };

  const submitHandler = () => {
    if (modalProp.subIndex !== undefined) {
      const newQuestion = { ...questions[modalProp.index] };
      newQuestion.questions = newQuestion.questions.map(
        (q: any, index: number) => {
          return index === modalProp.subIndex ? question : q;
        }
      );
      setInnerFields(modalProp.index, newQuestion);
    } else {
      setInnerFields(modalProp.index, question);
    }
    setModal(null);
  };

  return (
    <Modal
      width="725px"
      footer={null}
      onCancel={closeModal}
      visible={true}
      className="question-settings-modal"
    >
      <div className="fw-semibold font-ml text-secondary mb-4">
        {question ? QuestionLabelMap[question.type] : "Question"}
      </div>
      <Tabs style={{ minHeight: "400px" }}>
        <TabPane tab="Basic" key="basic">
          {question && (
            <BasicQuestionPropTab
              question={question}
              modifyQuestion={modifyQuestion}
            />
          )}
        </TabPane>
        {question && !noResonseFields.includes(question.type) && (
          <TabPane tab="Response code" key="Response code">
            <ResponseLogic
              question={question}
              modifyQuestion={modifyQuestion}
            />
          </TabPane>
        )}
        {question && !noLogicFields.includes(question.type) && (
          <TabPane tab="Logic Jump" key="logic jump">
            <BranchLogic
              question={question}
              modifyQuestion={modifyQuestion}
              questions={questions}
            />
          </TabPane>
        )}
        {question && question.type === "grid" && (
          <TabPane tab="Grid Configuration" key="grid">
            <GridLogic
              question={question}
              modifyQuestion={modifyQuestion}
              showChinese={showChinese}
            />
          </TabPane>
        )}
      </Tabs>
      <div className="d-flex align-items-center mt-4">
        <Button
          type="primary"
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={submitHandler}
        >
          <span className="fw-semibold font-sm">Submit</span>
        </Button>
        <Button
          style={{ height: "40px", borderRadius: "12px" }}
          className="me-4"
          onClick={closeModal}
        >
          <span className="fw-semibold font-sm">Cancel</span>
        </Button>
      </div>
    </Modal>
  );
};

type tabProps = {
  question: any;
  modifyQuestion: Function;
  preview?: boolean;
};

export const BasicQuestionPropTab: React.FC<tabProps> = ({
  question,
  modifyQuestion,
  preview,
}) => {
  const [otherChoice, setOtherChoice] = useState(false);
  const [otherType, setOtherType] = useState("text");
  const [otherLabel, setOtherLabel] = useState("");

  const { fields, type } = question;

  useEffect(() => {
    if (choice_types.includes(type)) {
      const otherField = fields?.find?.((choice: any) => {
        return choice.isOther;
      });
      if (otherField) {
        setOtherChoice(true);
        setOtherType(otherField.isOtherType);
        setOtherLabel(otherField.isOtherLabel);
      } else {
        setOtherChoice(false);
      }
    }
  }, [fields, type, setOtherChoice, setOtherType, setOtherLabel]);

  const modifyOtherField = (checked: boolean) => {
    if (checked) {
      const newQuestion = {
        ...question,
        fields: [
          ...question.fields,
          {
            type: "dropdown",
            id: uuid(),
            label: "other",
            label_ch: "other",
            code: "",
            isOther: true,
            isOtherType: "text",
            isOtherLabel: "Others, Specify",
          },
        ],
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        fields: question.fields.filter((choice: any) => !choice.isOther),
      };
      modifyQuestion(newQuestion);
    }
  };

  const modifyOtherConfig = (type: string, key: string) => {
    const newQuestion = {
      ...question,
      fields: [
        ...question.fields.map((field: any) => {
          if (field.isOther) {
            return {
              ...field,
              [key]: type,
            };
          } else {
            return field;
          }
        }),
      ],
    };
    modifyQuestion(newQuestion);
  };

  const onChange = (key: string, event: any, isProp?: boolean) => {
    if (isProp) {
      const newQuestion = {
        ...question,
        properties: {
          ...question.properties,
          [key]: event.target.value,
        },
      };
      modifyQuestion(newQuestion);
    } else {
      const newQuestion = {
        ...question,
        [key]: event.target.value,
      };
      modifyQuestion(newQuestion);
    }
  };

  const switchChange = (checked: boolean, key: string) => {
    const newQuestion = {
      ...question,
      [key]: checked,
    };
    modifyQuestion(newQuestion);
  };

  return (
    <div className="px-1">
      <div className="mt-4 mb-1">
        <div className="text-secondary font-m fw-medium mb-1">
          Variable Name
        </div>
        <Input
          className="singer-input"
          placeholder="Variable name"
          value={question.varName}
          onChange={(e) => onChange("varName", e)}
          readOnly={preview}
        />
      </div>
      <div className="mt-4 mb-1">
        <div className="text-secondary font-m fw-medium mb-1">Description</div>
        <Input
          className="singer-input"
          placeholder="Description"
          value={question.description}
          onChange={(e) => onChange("description", e)}
          readOnly={preview}
        />
      </div>
      {["number", "short_text", "long_text"].includes(question?.type) && (
        <div className="mt-4 mb-1">
          <div className="text-secondary font-m fw-medium mb-1">Formula</div>
          <Input
            placeholder="Formula"
            className="singer-input"
            value={question.formula}
            onChange={(e) => onChange("formula", e)}
            readOnly={preview}
          />
        </div>
      )}
      {question?.properties?.hasOwnProperty("maxRange") && (
        <div className="d-flex mb-1">
          {question?.properties?.hasOwnProperty("minRange") && (
            <div className="mt-4 me-4" style={{ flex: 1, minWidth: "0px" }}>
              <div className="text-secondary font-m fw-medium mb-1">
                Lower limits
              </div>
              <Input
                type="number"
                className="singer-input"
                value={question.properties.minRange}
                onChange={(e) => onChange("minRange", e, true)}
                readOnly={preview}
              />
            </div>
          )}
          <div className="mt-4" style={{ flex: 1, minWidth: "0px" }}>
            <div className="text-secondary font-m fw-medium mb-1">
              {question.type === "upload_file"
                ? "Max file size(in Megabytes)"
                : "Upper limits"}
            </div>
            <Input
              type="number"
              className="singer-input"
              value={question.properties.maxRange}
              onChange={(e) => onChange("maxRange", e, true)}
              readOnly={preview}
            />
          </div>
        </div>
      )}
      {question?.hasOwnProperty("required") && (
        <div className="d-flex align-items-center my-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Required Field
          </div>
          <Switch
            disabled={preview}
            checked={question.required}
            onChange={(checked) => switchChange(checked, "required")}
          />
        </div>
      )}
      {question?.hasOwnProperty("remark") && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Enable remark
          </div>
          <Switch
            checked={question.remark}
            disabled={preview}
            onChange={(checked) => switchChange(checked, "remark")}
          />
        </div>
      )}
      {othersField.includes(question.type) && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Other Option
          </div>
          <Switch
            checked={otherChoice}
            disabled={preview}
            onChange={modifyOtherField}
          />
        </div>
      )}

      {(question.type === "long_text" || question.type === "short_text") && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            PII Field
          </div>
          <Switch
            checked={question.hideResponse}
            disabled={preview}
            onChange={(checked) => switchChange(checked, "hideResponse")}
          />
        </div>
      )}

      {otherChoice && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Other Option Type
          </div>
          <Select
            className="custom-select-field"
            size="large"
            style={{ width: "200px" }}
            value={otherType}
            onChange={(val) => modifyOtherConfig(val, "isOtherType")}
          >
            <Select.Option value="text">Text</Select.Option>
            <Select.Option value="MM/YYYY">Month and Year</Select.Option>
          </Select>
        </div>
      )}

      {otherChoice && otherType === "text" && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Other Option Placeholder
          </div>
          <Input
            placeholder="Placeholder"
            className="singer-input"
            value={otherLabel}
            onChange={(e) => modifyOtherConfig(e.target.value, "isOtherLabel")}
            readOnly={preview}
            style={{ width: "200px" }}
          />
        </div>
      )}

      {number_types.includes(question.type) && (
        <div className="d-flex align-items-center mb-4">
          <div className="text-gray font-sm" style={{ width: "150px" }}>
            Allow Decimal
          </div>
          <Switch
            checked={question.enforceDecimal}
            disabled={preview}
            onChange={(checked) => switchChange(checked, "enforceDecimal")}
          />
        </div>
      )}
      {question.type === "score_input" && (
        <>
          <div className="d-flex align-items-center mb-4">
            <div className="text-gray font-sm" style={{ width: "150px" }}>
              Allow Image upload
            </div>
            <Switch
              checked={question?.uploadable}
              disabled={preview}
              onChange={(checked) => switchChange(checked, "uploadable")}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default QuestionPropertiesModal;
