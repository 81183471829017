import logo1 from "./assets/logo_1.png";
import logo2 from "./assets/logo_2.png";
import logo3 from "./assets/logo_3.png";

const LogoContainer = () => {
  return (
    <div className="go-logo-container">
      <img src={logo1} alt="memorial-sloan-kettering" />
      <img src={logo2} alt="go2-logo" />
      <img src={logo3} alt="cancer-center" />
    </div>
  );
};

export default LogoContainer;
